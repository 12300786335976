import 'dayjs/locale/de-ch';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);
dayjs.locale('de-ch');

// TODO: re-enable language handling when required
// const lang = i18n.language.substring(0, 2);
// let locale;
// let antLocale;
// switch (lang) {
//   case 'fr':
//     antLocale = frFR;
//     locale = 'fr-ch';
//     break;
//   case 'it':
//     antLocale = itIT;
//     locale = 'it-ch';
//     break;
//   case 'en':
//     antLocale = enUS;
//     locale = 'en-gb';
//     break;
//   case 'de':
//   default:
//     antLocale = deDE;
//     locale = 'de-ch';
//     break;
// }
// dayjs.locale(locale);
// document.documentElement.lang = `${lang}-ch`;
