import dayjs from 'dayjs';

export const franchiseChangeDisabledDate = dayjs().month(10).date(30); // November 30th
export const franchiseChangeEnabledDate = dayjs().month(1).date(1).add(1, 'year'); // February 1st of next year
export const cancellationDisabledDate = dayjs().month(10).date(30); // November 30th
export const cancellationEnabledDate = dayjs().month(1).date(1).add(1, 'year'); // February 1st of next year
export const startOfNewYear = dayjs().startOf('year').add(1, 'year'); // January 1st of next year

export const isFranchiseChangeDisabled = () => {
  const currentMonth = dayjs().get('month');
  const disabledMonths = [11, 0]; // December and January

  return disabledMonths.includes(currentMonth);
};

export const isCancellationDisabled = () => {
  const currentMonth = dayjs().get('month');
  const disabledMonths = [11, 0]; // December and January

  return disabledMonths.includes(currentMonth);
};
