import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {Descriptions, Popconfirm} from 'antd';
import dayjs from 'dayjs';
import BaseInsuranceCard from './cards/BaseInsuranceCardOverview';
import AddonInsuranceCard from './cards/AddonInsuranceCardOverview';
import {Person, TravelInsurancePersonType} from '../models/person';
import {useStore} from '../hooks/useStore';
import useBreakpoint from '../hooks/useBreakpoint';
import useCurrentDomainInformation from '../hooks/useCurrentDomainInformation';
import {CalculatorPaths, CancellationWizardPaths, TravelInsurancePaths} from '../pages/paths';
import {PersonType} from '../models/document-request';
import {AddonInsurance, AddonInsuranceType} from '../models/addon-insurance';
import {sortNumerically} from '../utils/sorters';
import Space from './shared/Space';
import {Config, GUTTER_SIZE} from '../config';
import {Box} from './shared/Box';
import Icon from './shared/Icon';
import {AvatarIcon, PenIcon, PriceOverviewIcon, TrashBinIcon} from './shared/icons';
import Button from './shared/Button';
import {Col, Row} from './layout/Grid';
import TravelInsuranceOverviewCard from '../pages/travel-insurance/overview/_components/TravelInsuranceOverviewCard';
import InfoBox from './shared/InfoBox';
import Text from './shared/Text';
import CancellationTypeOverviewCard
  from '../pages/cancellation-wizard/overview/_components/CancellationTypeOverviewCard';
import './PersonBlock.scss';

interface PersonBlockProps {
  person: Person;
  index: number;
  fullDetails?: boolean;
}

const PersonBlock: FC<PersonBlockProps> = ({person, index, fullDetails = false}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const store = useStore();
  const {isMobile} = useBreakpoint();
  const {
    isTravelInsurance,
    isCalculator,
    isCancellationWizard,
    currentStore,
  } = useCurrentDomainInformation();

  const addBaseInsurance = () => {
    store.setEditMode(true);
    store.calculatorStore.setCurrent(person);
    navigate(CalculatorPaths.productBase());
  };

  const addAddonInsurance = () => {
    store.setEditMode(true);
    store.calculatorStore.setCurrent(person);
    navigate(CalculatorPaths.productAddon());
  };

  const editPerson = () => {
    if (isTravelInsurance) {
      if (person.travelInsurancePersonType === TravelInsurancePersonType.POLICY_HOLDER) {
        store.setEditMode(true);
        navigate(TravelInsurancePaths.person());
      } else {
        store.travelInsuranceStore.setCurrent(person);
        store.setEditMode(true);
        navigate(TravelInsurancePaths.family());
      }
    } else if (isCancellationWizard) {
      store.setEditMode(true);
      store.cancellationWizardStore.setCurrent(person);
      navigate(CancellationWizardPaths.index());
    } else {
      store.calculatorStore.setCurrent(person);
      store.setEditMode(true);

      if (fullDetails) {
        navigate(CalculatorPaths.details());
      } else {
        navigate(CalculatorPaths.index());
      }
    }
  };

  const removePerson = () => {
    if (isTravelInsurance) {
      store.travelInsuranceStore.remove(person);
      return;
    }
    if (isCancellationWizard) {
      store.cancellationWizardStore.remove(person);
      return;
    }
    store.calculatorStore.remove(person);
    if (store.calculatorStore.models.length === 0) {
      const p = Person.create({type: PersonType.POLICY_HOLDER});
      store.calculatorStore.setCurrent(p);
      store.calculatorStore.add(p);
      navigate(CalculatorPaths.index());
    }
  };

  const changeAddonInsurances = () => {
    store.setEditMode(true);
    store.calculatorStore.setCurrent(person);
    navigate(CalculatorPaths.productAddon());
  };

  const addonInsuranceTypes = Object.values(AddonInsuranceType);
  const addonInsuranceSorter = (a: AddonInsurance, b: AddonInsurance) => sortNumerically(
    addonInsuranceTypes.indexOf(a.type),
    addonInsuranceTypes.indexOf(b.type)
  );
  const addonInsurances = person.addonInsurances !== undefined
    ? Object.values(person.addonInsurances).sort(addonInsuranceSorter)
    : [];

  const name = person.fullName || t('personCard.namePlaceholder', {number: index + 1});
  const noPreInsurerText = person.moveInFromAbroad ? t('person.preInsurerAbroad') : t('person.noPreInsurer');

  return (
    <Space
      direction={'vertical'}
      fullWidth
      className={fullDetails ? 'person-block full-details' : 'person-block'}
      size={GUTTER_SIZE}
    >
      <div>
        <div className={'block-header'}>
          <div className={'person-header'}>
            <Box flex={'0 1 50px'}>
              <Icon component={(person.isNewBorn && !isTravelInsurance) ? PriceOverviewIcon : AvatarIcon} size={50}/>
            </Box>
            <Box flex={'auto'}>
              {(isCalculator && person.isNewBorn) ? (
                <>{t('personCard.priceOverview')}</>
              ) : (
                <div>
                  <div className={'name'}>
                    {name}
                    &nbsp;
                    {isTravelInsurance && person.travelInsurancePersonType === TravelInsurancePersonType.PARTNER && (
                      t('travelInsurance.overview.partnerTitle')
                    )}
                    {isTravelInsurance && person.travelInsurancePersonType === TravelInsurancePersonType.CHILD && (
                      t('travelInsurance.overview.childTitle', {index})
                    )}
                  </div>
                  <div className={'details'}>
                    {person.birthday ? dayjs(person.birthday).format(Config.dateFormat) : ''}
                    <br/>
                    {!isTravelInsurance && currentStore.city?.label}
                  </div>
                </div>
              )}
            </Box>
            <Box flex={'0 1 50px'} className={'edit'}>
              <Space direction={'vertical'}>
                {!person.isNewBorn && (
                  <Button type={'link'} onClick={editPerson}>
                    <Icon component={PenIcon} size={22}/>
                  </Button>
                )}
                {((isTravelInsurance && person.travelInsurancePersonType !== TravelInsurancePersonType.POLICY_HOLDER)
                  || (!isTravelInsurance && !fullDetails && !person.isNewBorn)
                  || (isCancellationWizard && !person.isPolicyHolder)) && (
                  <Popconfirm
                    placement={'left'}
                    title={t('person.removeQuestion', {name})}
                    onConfirm={removePerson}
                    okText={t('label.yes')}
                    cancelText={t('label.no')}
                  >
                    <div>
                      <Button type={'link'} skin={'danger'}>
                        <Icon component={TrashBinIcon} size={22}/>
                      </Button>
                    </div>
                  </Popconfirm>
                )}
              </Space>
            </Box>
          </div>
          {isTravelInsurance && (
            <div className={'person-body'}>
              <Descriptions size={'small'} layout={'vertical'} bordered column={isMobile ? 1 : 2}>
                {person.sex !== undefined && (
                  <Descriptions.Item label={`${t('label.sex')} / ${t('label.name')}`}>
                    {t(`sex.${person.sex}`)}
                    {' '}
                    {person.fullName}
                  </Descriptions.Item>
                )}
                {person.travelInsurancePersonType === TravelInsurancePersonType.POLICY_HOLDER && (
                  <Descriptions.Item label={t('label.email')}>
                    {person.email || store.travelInsuranceStore.email}
                  </Descriptions.Item>
                )}
                {(person.previousInsurer
                  && (
                    <Descriptions.Item label={t('label.insurer')}>
                      {person.previousInsurer.name}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            </div>
          )}
          {isCalculator && fullDetails && (
            <div className={'person-body'}>
              <Descriptions size={'small'} layout={'vertical'} bordered column={isMobile ? 1 : 2}>
                <Descriptions.Item label={`${t('label.sex')} / ${t('label.name')}`}>
                  {t(`sex.${person.sex}`)}
                  {' '}
                  {person.fullName}
                </Descriptions.Item>
                {person.isEmailRequired && (
                  <Descriptions.Item label={t('label.email')}>
                    {person.email}
                  </Descriptions.Item>
                )}
                {person.isMobileRequired && (
                  <Descriptions.Item label={t('label.mobile')}>
                    {person.mobile}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t('person.residence')}>
                  {person.residenceInSwitzerland ? t('person.residenceInSwitzerland') : t('person.moveInFromAbroad')}
                </Descriptions.Item>
                {person.hasBaseInsurance && person.residenceInSwitzerland && (
                  <Descriptions.Item label={t('person.previousInsurer')}>
                    {person.previousInsurer ? person.previousInsurer.name : noPreInsurerText}
                  </Descriptions.Item>
                )}
                {!person.residenceInSwitzerland && (
                  <Descriptions.Item label={t('person.dateOfArrival')}>
                    {dayjs(person.dateOfArrival).format(Config.dateFormat)}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t('person.insuranceStart')}>
                  {dayjs(person.insuranceStart).format(Config.dateFormatWithWords)}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
          {isCancellationWizard && (
            <div className={'person-body'}>
              <Descriptions size={'small'} layout={'vertical'} bordered column={isMobile ? 1 : 2}>
                {person.sex !== undefined && (
                  <Descriptions.Item label={`${t('label.sex')} / ${t('label.name')}`}>
                    {t(`sex.${person.sex}`)}
                    {' '}
                    {person.fullName}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t('label.insuranceNumber')}>
                  {person.insuranceNo}
                </Descriptions.Item>
                <Descriptions.Item label={t('label.birthday')}>
                  {person.birthday ? dayjs(person.birthday).format(Config.dateFormat) : ''}
                </Descriptions.Item>
                <Descriptions.Item label={t('label.cancellationAt')}>
                  {store.cancellationWizardStore.cancellationDate.format(Config.dateFormat)}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
        {isTravelInsurance
          && person.travelInsurancePersonType === TravelInsurancePersonType.POLICY_HOLDER
          && store.travelInsuranceStore.travelInsuranceRate
          && (
            <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} className={'person-insurances'}>
              <Col xs={24} className={'travel-insurance-wrapper'}>
                <Col xs={24} lg={18} className={'travel-insurance'}>
                  <TravelInsuranceOverviewCard/>
                </Col>
              </Col>
            </Row>
          )}
        {isCalculator && (
          <Row gutter={[GUTTER_SIZE, fullDetails ? 0 : GUTTER_SIZE]} className={'person-insurances'}>
            <Col xs={24} lg={12} className={'base-insurance'}>
              {person.baseInsurance === undefined ? !fullDetails && (
                <Button skin={'primary-gray-blue'} onClick={addBaseInsurance}>{t('baseInsurance.add')}</Button>
              ) : (
                <BaseInsuranceCard person={person} fullDetails={fullDetails}/>
              )}
            </Col>
            {!person.isSenior && (
              <Col xs={24} lg={12} className={'addon-insurances'}>
                <Space fullWidth direction={'vertical'} size={GUTTER_SIZE}>
                  {addonInsurances.length > 0 && (
                    <Space fullWidth direction={'vertical'} size={GUTTER_SIZE}>
                      {addonInsurances.map((addonInsurance) => (
                        <AddonInsuranceCard
                          key={addonInsurance.type}
                          person={person}
                          addonInsurance={addonInsurance}
                          editable={!fullDetails && !person.isNewBorn}
                        />
                      ))}
                    </Space>
                  )}
                  {person.hasAddonInsurances && !fullDetails && !person.isNewBorn && (
                    <Button className={'text-small'} type={'link'} onClick={() => changeAddonInsurances()}>
                      {t('addonInsurance.change')}
                    </Button>
                  )}
                  {!person.hasAddonInsurances && !fullDetails && (
                    <Button skin={'primary-gray-blue'} onClick={addAddonInsurance}>{t('addonInsurance.add')}</Button>
                  )}
                </Space>
              </Col>
            )}
          </Row>
        )}
        {isCancellationWizard && (
          <Row gutter={[GUTTER_SIZE, GUTTER_SIZE]} className={'person-insurances'}>
            <Col xs={24} className={'cancellation-wrapper'}>
              <Col xs={24} lg={12} className={'cancellation'}>
                <CancellationTypeOverviewCard/>
              </Col>
            </Col>
          </Row>
        )}
      </div>
      {isCalculator && !person.isAddonInsuranceSelectionValid && (
        <InfoBox title={t('label.attention')}>
          <Text>
            {t('addonInsurance.selectionInvalid')}
          </Text>
        </InfoBox>
      )}
    </Space>
  );
};

export default observer(PersonBlock);
