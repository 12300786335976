export const CalculatorPaths = {
  index: () => '/offer',
  productSelection: () => `${CalculatorPaths.index()}/product`,
  productBase: () => `${CalculatorPaths.index()}/product/base`,
  productAddon: () => `${CalculatorPaths.index()}/product/addon`,
  overview: () => `${CalculatorPaths.index()}/overview`,
  details: () => `${CalculatorPaths.index()}/details`,
  doctor: () => `${CalculatorPaths.index()}/details/doctor`,
  payment: () => `${CalculatorPaths.index()}/details/payment`,
  contact: () => `${CalculatorPaths.index()}/details/contact`,
  finish: () => `${CalculatorPaths.index()}/finish/overview`,
  successSubmission: () => `${CalculatorPaths.index()}/finish/success/submission`,
  successRequest: () => `${CalculatorPaths.index()}/finish/success/request`,
  successNewBorn: () => `${CalculatorPaths.index()}/finish/success/new-born`,
};

export const FranchiseChangeDisabledPath = '/franchise-change-disabled';

export const FranchiseChangePaths = {
  index: () => '/franchise-change',
  current: () => `${FranchiseChangePaths.index()}/current`,
  new: () => `${FranchiseChangePaths.index()}/new`,
  person: () => `${FranchiseChangePaths.index()}/person`,
  overview: () => `${FranchiseChangePaths.index()}/overview`,
  successSubmission: () => `${FranchiseChangePaths.index()}/overview/success`,
};

export const TravelInsurancePaths = {
  index: () => '/travel-insurance',
  productSelection: () => `${TravelInsurancePaths.index()}/product`,
  person: () => `${TravelInsurancePaths.index()}/person`,
  family: () => `${TravelInsurancePaths.index()}/person/family`,
  overview: () => `${TravelInsurancePaths.index()}/overview`,
  successSubmission: () => `${TravelInsurancePaths.index()}/finish/success/:policyNr`,
  paymentFailed: () => `${TravelInsurancePaths.index()}/finish/payment-failed`,
};

export const CancellationOverviewPath = '/cancellation';

export const CancellationDisabledPath = '/cancellation-disabled';

export const CancellationUploadPaths = {
  index: () => '/cancellation-upload',
  successSubmission: () => `${CancellationUploadPaths.index()}/success`,
};

export const CancellationWizardPaths = {
  index: () => '/cancellation-wizard',
  overview: () => `${CancellationWizardPaths.index()}/overview`,
  verification: () => `${CancellationWizardPaths.index()}/verification`,
  optIn: () => `${CancellationWizardPaths.index()}/opt-in`,
  success: () => `${CancellationWizardPaths.index()}/success`,
};

export const CancellationDeathPaths = {
  index: () => '/cancellation-death',
  successSubmission: () => `${CancellationDeathPaths.index()}/success`,
};

export const CancellationLeavePaths = {
  index: () => '/cancellation-leave',
  questions: () => `${CancellationLeavePaths.index()}/questions`,
  cancellation: () => `${CancellationLeavePaths.index()}/cancellation`,
  successSubmission: () => `${CancellationLeavePaths.index()}/success`,
};
