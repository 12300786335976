import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Cancellation from './cancellation/Cancellation';
import Success from './success/Success';
import NotFound from '../not-found/NotFound';
import {isCancellationDisabled} from '../../utils/date';
import {CancellationDisabledPath} from '../paths';

const CancellationUploadRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isCancellationDisabled()) {
      navigate(CancellationDisabledPath);
    }
  }, []);

  return (
    <Routes>
      <Route index element={<Cancellation/>}/>
      <Route path={'success'} element={<Success/>}/>
      <Route path={'*'} element={<NotFound/>}/>
    </Routes>
  );
};

export default observer(CancellationUploadRoutes);
