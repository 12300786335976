import './i18n/date';
import {ConfigProvider} from 'antd';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import deDE from 'antd/lib/locale/de_DE';
import AppRouter from './pages/AppRouter';
import ScrollToTop from './components/layout/ScrollToTop';
import {DomainContextProvider} from './contexts/DomainContext';
import {StoreContextProvider} from './contexts/StoreContext';

import {theme} from './theme';
import 'antd/dist/reset.css';
import './App.scss';

document.documentElement.lang = 'de-ch';

const App = () => {
  // Google Tag Manager
  const tagManagerId = 'GTM-WD4PNQJ';
  const script = document.createElement('script');
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${tagManagerId}');`;
  const noScript = document.createElement('noscript');
  noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}" `
    + 'height="0" width="0" style="display:none;visibility:hidden"></iframe>';

  document.head.insertBefore(script, document.head.childNodes[0]);
  document.body.insertBefore(noScript, document.body.childNodes[0]);

  return (
    <ConfigProvider
      theme={theme}
      locale={deDE}
    >
      <BrowserRouter>
        <StoreContextProvider>
          <DomainContextProvider>
            <ScrollToTop>
              <AppRouter/>
            </ScrollToTop>
          </DomainContextProvider>
        </StoreContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
