import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Person from './person/Person';
import Success from './success/Success';
import Overview from './overview/Overview';
import Verification from './verification/Verification';
import OptIn from './opt-in/OptIn';
import {useStore} from '../../hooks/useStore';
import {CancellationDisabledPath, CancellationWizardPaths} from '../paths';
import NotFound from '../not-found/NotFound';
import {isCancellationDisabled} from '../../utils/date';

const CancellationWizardRoutes = () => {
  const navigate = useNavigate();
  const store = useStore();

  useEffect(() => {
    store.cancellationWizardStore.reset();
    if (isCancellationDisabled()) {
      navigate(CancellationDisabledPath);
    } else {
      navigate(CancellationWizardPaths.index());
    }
  }, []);

  return (
    <Routes>
      <Route index element={<Person/>}/>
      <Route path={'overview'} element={<Overview/>}/>
      <Route path={'verification'} element={<Verification/>}/>
      <Route path={'opt-in'} element={<OptIn/>}/>
      <Route path={'success'} element={<Success/>}/>
      <Route path={'*'} element={<NotFound/>}/>
    </Routes>
  );
};

export default observer(CancellationWizardRoutes);
