import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Layout} from 'antd';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import {
  CalculatorPaths,
  CancellationDeathPaths,
  CancellationDisabledPath,
  CancellationLeavePaths,
  CancellationOverviewPath,
  CancellationUploadPaths,
  CancellationWizardPaths,
  FranchiseChangeDisabledPath,
  FranchiseChangePaths,
  TravelInsurancePaths
} from './paths';
import useCurrentDomainInformation from '../hooks/useCurrentDomainInformation';
import CalculatorRoutes from './calculator/Routes';
import FranchiseChangeRoutes from './franchise-change/Routes';
import TravelInsuranceRoutes from './travel-insurance/Routes';
import CancellationUploadRoutes from './cancellation-upload/Routes';
import NotFound from './not-found/NotFound';
import CancellationWizardRoutes from './cancellation-wizard/Routes';
import EnvironmentInfo from '../components/layout/EnvironmentInfo';
import CancellationDeathRoutes from './cancellation-death/Routes';
import CancellationLeaveRoutes from './cancellation-leave/Routes';
import CancellationOverview from './cancellation-overview/CancellationOverview';
import CancellationDisabled from './cancellation-disabled/CancellationDisabled';
import FranchiseChangeDisabled from './franchise-change-disabled/FranchiseChangeDisabled';

const AppRouter = () => {
  const location = useLocation();
  const {t} = useTranslation();
  const {
    isCalculator,
    isFranchiseChange,
    isTravelInsurance,
    isCancellationUpload,
    isCancellationWizard,
    isCancellationDeath,
    isCancellationLeave,
  } = useCurrentDomainInformation();

  useEffect(() => {
    if (isFranchiseChange) {
      document.title = t('pageTitle.franchiseChange');
    } else if (isTravelInsurance) {
      document.title = t('pageTitle.travelInsurance');
    } else if (isCalculator) {
      document.title = t('pageTitle.calculator');
    } else if (isCancellationUpload || isCancellationWizard) {
      document.title = t('pageTitle.cancellation');
    } else if (isCancellationDeath) {
      document.title = t('pageTitle.cancellationDeath');
    } else if (isCancellationLeave) {
      document.title = t('pageTitle.cancellationLeave');
    }
  }, [location.pathname]);

  return (
    <Layout>
      <EnvironmentInfo/>
      <Header/>
      <Layout.Content>
        <Routes>
          <Route path={''} element={<Navigate to={CalculatorPaths.index()} replace/>}/>

          <Route path={`${CalculatorPaths.index()}/*`} element={<CalculatorRoutes/>}/>
          <Route path={FranchiseChangeDisabledPath} element={<FranchiseChangeDisabled/>}/>
          <Route path={`${FranchiseChangePaths.index()}/*`} element={<FranchiseChangeRoutes/>}/>
          <Route path={`${TravelInsurancePaths.index()}/*`} element={<TravelInsuranceRoutes/>}/>

          <Route path={CancellationOverviewPath} element={<CancellationOverview/>}/>
          <Route path={CancellationDisabledPath} element={<CancellationDisabled/>}/>
          <Route path={`${CancellationUploadPaths.index()}/*`} element={<CancellationUploadRoutes/>}/>
          <Route path={`${CancellationWizardPaths.index()}/*`} element={<CancellationWizardRoutes/>}/>
          <Route path={`${CancellationDeathPaths.index()}/*`} element={<CancellationDeathRoutes/>}/>
          <Route path={`${CancellationLeavePaths.index()}/*`} element={<CancellationLeaveRoutes/>}/>

          <Route path={'*'} element={<NotFound/>}/>
        </Routes>
      </Layout.Content>
      <Footer/>
    </Layout>
  );
};

export default observer(AppRouter);
