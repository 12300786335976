import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DatePicker, Form, Input, Radio} from 'antd';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import dayjs, {Dayjs} from 'dayjs';
import {Validators} from '../../../../utils/validators';
import {useStore} from '../../../../hooks/useStore';
import {IPerson, Sex} from '../../../../models/person';
import InfoBox from '../../../../components/shared/InfoBox';
import Select from '../../../../components/shared/Select';
import Checkbox from '../../../../components/shared/Checkbox';
import {PreviousInsurer} from '../../../../models/previous-insurer';
import {Config} from '../../../../config';
import StepsNavigationButtons from '../../../../components/StepsNavigationButtons';
import Text from '../../../../components/shared/Text';
import {updateInsuranceRates} from '../../../../utils/insurance-rates';
import {sortAlphabetically} from '../../../../utils/sorters';
import {CalculatorPaths} from '../../../paths';
import useCurrentDomainInformation from '../../../../hooks/useCurrentDomainInformation';

// eslint-disable-next-line max-len
interface FormValues extends Required<Pick<IPerson, 'sex' | 'firstName' | 'lastName' | 'residenceInSwitzerland' | 'moveInFromAbroad' | 'dateOfArrival'>> {
  previousInsurer: PreviousInsurer['id'];
  insuranceStart: number;
  email?: string;
  mobile?: string;
}

interface PersonDetailsFormProps {
  afterSubmit: () => void;
}

const PersonDetailsForm: FC<PersonDetailsFormProps> = ({afterSubmit}) => {
  const {t} = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const person = store.calculatorStore.current;
  const [showInsurerSelection, setShowInsurerSelection] = useState(person?.residenceInSwitzerland === true);
  const [showDateOfArrival, setShowDateOfArrival] = useState(person?.moveInFromAbroad === true);
  const [previousInsurers, setPreviousInsurers] = useState<{ label: string, value: number }[]>([]);
  const [initialStartDate, setInitialStartDate] = useState<Dayjs | undefined>(person?.insuranceStart);
  const {currentStore} = useCurrentDomainInformation();

  useEffect(() => {
    if (person) {
      setInitialStartDate(person.insuranceStart);
    }
  }, [person]);

  if (!person) {
    return null;
  }

  const onFinish = (values: FormValues) => {
    person.setSex(values.sex);
    person.setFirstName(values.firstName);
    person.setLastName(values.lastName);
    person.setResidenceInSwitzerland(values.residenceInSwitzerland);
    person.setMoveInFromAbroad(values.moveInFromAbroad);
    person.setPreviousInsurer(
      values.previousInsurer
        ? store.previousInsurerStore.getById(values.previousInsurer)
        : undefined
    );
    person.setDateOfArrival(values.dateOfArrival);

    if (person.isMobileRequired) {
      person.setMobile(values.mobile);
    }

    if (person.isEmailRequired) {
      person.setEmail(values.email);
    }

    updateInsuranceRates(person, store, currentStore);

    store.calculatorStore.saveToSession();
    afterSubmit();
  };

  const previousStep = () => {
    navigate(CalculatorPaths.overview());
  };

  const initialValues: Partial<FormValues> = {
    ...person,
    previousInsurer: person.previousInsurer?.id
      || (person.moveInFromAbroad || !person.hasBaseInsurance ? 0 : undefined),
    insuranceStart: person.insuranceStart.valueOf(),
  };

  const updatePreviousInsurers = (showMoveInOption: boolean) => {
    const options = store.previousInsurerStore.models.filter((insurer) => !insurer.deletedAt)
      .map((insurer) => ({label: insurer.name, value: insurer.id}))
      .sort((a, b) => sortAlphabetically(a.label, b.label));
    if (!person.hasBaseInsurance) {
      options.unshift({
        label: t('person.noPreInsurer'),
        value: 0,
      });
    } else if (showMoveInOption) {
      options.unshift({
        label: t('person.preInsurerAbroad'),
        value: 0,
      });
    }
    setPreviousInsurers(options);
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    updatePreviousInsurers(person.moveInFromAbroad);
  }, [person.uuid]);

  useEffect(() => {
    if (!store.previousInsurerStore.isLoading) {
      updatePreviousInsurers(person.moveInFromAbroad);
    }
  }, [store.previousInsurerStore.isLoading]);

  const onValuesChange = (changedValues: Partial<FormValues>, allValues: FormValues) => {
    if (changedValues.residenceInSwitzerland !== undefined) {
      form.setFieldsValue({
        moveInFromAbroad: true,
      });
      setShowDateOfArrival(true);
      updatePreviousInsurers(true);
      form.setFieldsValue({
        previousInsurer: 0,
      });
    }

    if (changedValues.moveInFromAbroad !== undefined) {
      updatePreviousInsurers(changedValues.moveInFromAbroad);
      form.setFieldsValue({
        previousInsurer: changedValues.moveInFromAbroad || !person.hasBaseInsurance ? 0 : undefined,
      });

      if (!changedValues.moveInFromAbroad && initialStartDate) {
        person.setInsuranceStart(initialStartDate);
        form.setFieldsValue({insuranceStart: initialStartDate.valueOf()});
      }
    }

    if (!allValues.moveInFromAbroad) {
      form.setFieldsValue({
        dateOfArrival: undefined,
      });
    }

    if (allValues.dateOfArrival !== undefined && allValues.moveInFromAbroad) {
      let date = dayjs();

      const arrivalMoment = dayjs(form.getFieldValue('dateOfArrival'));
      if (arrivalMoment.isSameOrAfter(dayjs().subtract(3, 'month'), 'day')) {
        date = dayjs(form.getFieldValue('dateOfArrival'));
      }

      person.setInsuranceStart(date);
      form.setFieldsValue({
        insuranceStart: date.valueOf(),
      });
    }
  };

  return (
    <Form<FormValues>
      form={form}
      layout={'vertical'}
      colon={false}
      labelAlign={'left'}
      requiredMark={false}
      onFinish={onFinish}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Form.Item>
        <InfoBox title={t('person.detailsInfo.title')}>
          {t('person.detailsInfo.text')}
        </InfoBox>
      </Form.Item>
      <Form.Item
        name={'sex'}
        label={t('label.sex')}
        rules={[Validators.required(t('validator.salutationRequired'))]}
      >
        <Radio.Group>
          {Object.values(Sex).map((sex) => (
            <Radio key={sex} value={sex}>{t(`sex.${sex}`)}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={'firstName'}
        label={t('label.firstName')}
        rules={[Validators.required(t('validator.firstNameRequired')), Validators.noSpecialCharacters]}
      >
        <Input placeholder={t('label.firstName')} maxLength={50}/>
      </Form.Item>
      <Form.Item
        name={'lastName'}
        label={t('label.name')}
        rules={[Validators.required(t('validator.nameRequired')), Validators.noSpecialCharacters]}
      >
        <Input placeholder={t('label.name')} maxLength={50}/>
      </Form.Item>
      {person.isEmailRequired && (
        <Form.Item
          name={'email'}
          label={t('label.email')}
          rules={[Validators.required(t('validator.emailRequired')), Validators.email]}
        >
          <Input placeholder={t('label.email')} maxLength={255}/>
        </Form.Item>
      )}
      {person.isMobileRequired && (
        <Form.Item
          name={'mobile'}
          label={t('label.mobile')}
          rules={[Validators.required(t('validator.mobileRequired')), Validators.phone]}
        >
          <Input placeholder={t('label.mobile')} maxLength={50}/>
        </Form.Item>
      )}
      <Form.Item
        name={'residenceInSwitzerland'}
        valuePropName={'checked'}
      >
        <Checkbox onChange={({target: {checked}}) => setShowInsurerSelection(checked)}>
          {t('person.residenceInSwitzerland')}
        </Checkbox>
      </Form.Item>
      {showInsurerSelection ? (
        person.hasBaseInsurance && (
          <Form.Item
            name={'previousInsurer'}
            label={t('person.selectPreviousInsurer')}
            rules={[Validators.requiredIf(showInsurerSelection, t('validator.previousInsurerRequired'))]}
          >
            <Select
              showSearch
              loading={store.previousInsurerStore.isLoading}
              options={previousInsurers}
              placeholder={t('person.selectPreviousInsurer')}
            />
          </Form.Item>
        )
      ) : (
        <Form.Item>
          <InfoBox title={t('person.switzerlandResidenceInfo.title')}>
            <Text>
              <ReactMarkdown linkTarget={'_blank'}>
                {t('person.switzerlandResidenceInfo.text', {url: Config.aquilanaContactUrl})}
              </ReactMarkdown>
            </Text>
          </InfoBox>
        </Form.Item>
      )}
      <Form.Item
        name={'moveInFromAbroad'}
        valuePropName={'checked'}
      >
        <Checkbox onChange={({target: {checked}}) => setShowDateOfArrival(checked)}>
          {t('person.moveInFromAbroad')}
        </Checkbox>
      </Form.Item>
      {showDateOfArrival && (
        <>
          <Form.Item
            name={'dateOfArrival'}
            label={t('person.dateOfArrival')}
            rules={[Validators.requiredIf(showDateOfArrival, t('validator.dateOfArrivalRequired'))]}
          >
            <DatePicker
              format={Config.dateFormat}
              placeholder={t('label.dateFormat')}
              disabledDate={(d) => d.isBefore(dayjs().startOf('day').subtract(1, 'year'))
                || d.isAfter(dayjs().endOf('day').add(2, 'years'))}
            />
          </Form.Item>
          {person.hasBaseInsurance && (
            <Form.Item>
              <InfoBox title={t('person.dateOfArrivalInfo.title')}>
                {t('person.dateOfArrivalInfo.text')}
              </InfoBox>
            </Form.Item>
          )}
        </>
      )}
      <Form.Item
        name={'insuranceStart'}
        label={t('person.insuranceStart')}
        rules={[Validators.required(t('validator.insuranceStartRequired'))]}
      >
        <Select
          disabled
          options={[
            {
              label: dayjs(person.insuranceStart).format(Config.dateFormatWithWords),
              value: person.insuranceStart.valueOf(),
            },
          ]}
        />
      </Form.Item>
      <StepsNavigationButtons
        backHidden={store.editMode}
        back={() => previousStep()}
        next={() => form.submit()}
        nextDisabled={!showInsurerSelection}
      />
    </Form>
  );
};

export default observer(PersonDetailsForm);
