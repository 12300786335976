// eslint-disable-next-line import/no-cycle
import {DomainContextInformation} from '../../contexts/DomainContext';

export const isFranchiseContext = (path: string) => {
  if (!path) {
    return false;
  }
  if (path.match(/^\/franchise-change-disabled/)) {
    return false;
  }
  if (!path.match(/^\/franchise-change/)) {
    return false;
  }

  return true;
};

export const FRANCHISE_CHANGE_CONTEXT_NAME = 'franchise';

export const FranchiseChangeContext: DomainContextInformation = {
  name: FRANCHISE_CHANGE_CONTEXT_NAME,
  matches: isFranchiseContext,
};
