import {useTranslation} from 'react-i18next';
import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import Container from '../../../components/layout/Container';
import {BaseInsurance} from '../../../models/base-insurance';
import {useStore} from '../../../hooks/useStore';
import StepsNavigationButtons from '../../../components/StepsNavigationButtons';
import Text from '../../../components/shared/Text';
import Space from '../../../components/shared/Space';
import Select, {SelectOption} from '../../../components/shared/Select';
import {Config} from '../../../config';
import {CalculatorPaths} from '../../paths';
import InsuranceSelection from './_components/InsuranceSelection';

const ProductSelection: FC = () => {
  const store = useStore();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const person = store.calculatorStore.current;
  const [baseInsuranceSelected, setBaseInsuranceSelected] = useState(person.baseInsurance !== undefined);
  const [addonInsuranceSelected, setAddonInsuranceSelected] = useState(person.addonInsurances !== undefined);

  const insuranceStartOptions: SelectOption<number>[] = [];
  let date = dayjs().startOf('month');
  const oneYear = dayjs().startOf('month').add(12, 'months');
  const maxDate = dayjs(store.premiumVersionStore.maxDate);

  while (date < maxDate && date < oneYear) {
    insuranceStartOptions.push({
      label: date.format(Config.dateFormatWithWords),
      value: date.valueOf(),
    });
    date = date.add(1, 'month');
  }

  useEffect(() => {
    if (person) {
      if (baseInsuranceSelected) {
        if (!person.baseInsurance) {
          person.setBaseInsurance(new BaseInsurance());
        }
      } else {
        person.setBaseInsurance(undefined);
      }
    }
  }, [baseInsuranceSelected]);

  useEffect(() => {
    if (person) {
      if (addonInsuranceSelected) {
        if (!person.addonInsurances !== undefined) {
          person.setAddonInsurances({});
        }
      } else {
        person.setAddonInsurances(undefined);
      }
    }
  }, [addonInsuranceSelected]);

  if (!person) {
    navigate(CalculatorPaths.index());
    navigate(CalculatorPaths.index());
    return null;
  }

  const previousStep = () => {
    navigate(CalculatorPaths.index());
  };

  const nextStep = () => {
    store.calculatorStore.saveToSession();

    if (baseInsuranceSelected) {
      navigate(CalculatorPaths.productBase());
    } else {
      navigate(CalculatorPaths.productAddon());
    }
  };

  if (!(person.birthday && store.calculatorStore.city)) {
    return (
      <Navigate replace to={CalculatorPaths.index()}/>
    );
  }

  const onStartDateChange = (value: number) => {
    person.setInsuranceStart(dayjs(value));
    store.calculatorStore.saveToSession();
  };

  return (
    <>
      <Container background={'white'}>
        <Text header={1}>{t('stepProduct.title')}</Text>
        <Text size={'intro'}>{t('stepProduct.text')}</Text>
      </Container>
      <Container background={'white'}>
        <Text header={4}>{t('person.insuranceStart')}</Text>
        <Select
          onChange={onStartDateChange}
          defaultValue={person.insuranceStart?.valueOf()}
          style={{width: '50%', marginTop: 20}}
          options={insuranceStartOptions}
          placeholder={t('person.insuranceStart')}
        />
      </Container>
      <Container background={'light'} flex={1}>
        <Space fullWidth direction={'vertical'} size={'large'} style={{height: '100%', flex: 1}}>
          <InsuranceSelection
            baseInsuranceSelected={baseInsuranceSelected}
            setBaseInsuranceSelected={setBaseInsuranceSelected}
            addonInsuranceSelected={addonInsuranceSelected}
            setAddonInsuranceSelected={setAddonInsuranceSelected}
          />
          <StepsNavigationButtons
            back={() => previousStep()}
            next={() => nextStep()}
            nextDisabled={!baseInsuranceSelected && !addonInsuranceSelected}
          />
        </Space>
      </Container>
    </>
  );
};

export default observer(ProductSelection);
